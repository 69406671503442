<template>
  <div class="enterprise-info-page wrapper page-wrap">
    <a-spin :spinning="loadingForm">
      <a-form :model="form" ref="formRef" :colon="colon" layout="vertical">
        <div class="info-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12">
              <a-form-item name="park_id" label="入驻园区">
                <a-select v-model:value="form.park_id" size="large" disabled>
                  <a-select-option
                    v-for="(item, index) in park"
                    :value="item.park_id"
                    :key="index"
                    >{{ item.park_name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="enterprise_name" label="企业名称">
                <a-input
                  v-model:value="form.enterprise_name"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="enterprise_logo" label="企业LOGO">
                <upload-image
                  v-model:imageList="form.enterprise_logo"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_logo')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="enterprise_usci" label="统一社会信用代码">
                <a-input
                  v-model:value="form.enterprise_usci"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="enterprise_legal_person" label="法人姓名">
                <a-input
                  v-model:value="form.enterprise_legal_person"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="enterprise_people_num" label="企业人数（人）">
                <a-input
                  v-model:value="form.enterprise_people_num"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="enterprise_address" label="注册地址">
                <a-input
                  v-model:value="form.enterprise_address"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="enterprise_contact_name" label="联系人姓名">
                <a-input
                  v-model:value="form.enterprise_contact_name"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="enterprise_contact_phone" label="联系人电话">
                <a-input
                  v-model:value="form.enterprise_contact_phone"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item name="enterprise_introduction" label="企业简介">
                <a-textarea
                  v-model:value="form.enterprise_introduction"
                  size="large"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  readonly
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="apply_sheet"
                label="唐山人力资源服务产业园入园申请表（附件1）"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.apply_sheet"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('apply_sheet')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="license"
                label="企业（机构）营业执照副本复印件"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.license"
                  :imageCount="1"
                  :couldRename="false"
                  :disabled="enterprise?.enterprise_enter_status == 4"
                  @change="getImageStatus('license')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="idcardimg"
                label="企业法人身份证复印件"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.idcardimg"
                  :imageCount="2"
                  :couldRename="false"
                  :disabled="enterprise?.enterprise_enter_status == 4"
                  @change="getImageStatus('idcardimg')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="permit"
                label="人力资源服务许可证或劳务派遣经营许可证等相关资质证件复印件"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.permit"
                  :imageCount="9"
                  :couldRename="false"
                  :disabled="enterprise?.enterprise_enter_status == 4"
                  @change="getImageStatus('permit')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="financial"
                label="经营性人力资源资源服务机构上一年度审计报告或财务报表（包含利润表和资产负债表）复印件"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.financial"
                  :imageCount="9"
                  :couldRename="false"
                  :disabled="enterprise?.enterprise_enter_status == 4"
                  @change="getImageStatus('financial')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="honor"
                label="企业（机构）简介及所获荣誉证书复印件"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.honor"
                  :imageCount="9"
                  :couldRename="false"
                  :disabled="enterprise?.enterprise_enter_status == 4"
                  @change="getImageStatus('honor')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="real"
                label="企业（机构）对提交材料的真实性声明（附件2）"
                :rules="{
                  required: true,
                  message: '请上传',
                }"
              >
                <upload-image
                  v-model:imageList="form.real"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('real')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="24">
              <a-form-item name="enterprise_license" label="营业执照">
                <upload-image
                  v-model:imageList="form.enterprise_license"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_license')"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="enterprise_material" label="申请材料">
                <upload-image
                  v-model:imageList="form.enterprise_material"
                  :imageCount="1"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_material')"
                ></upload-image>
              </a-form-item>
            </a-col> -->
            <a-col :span="24">
              <a-form-item label="企业用户">
                <a-space>
                  <a-button
                    type="primary"
                    size="large"
                    @click="
                      () => {
                        visible = true;
                      }
                    "
                    >新增企业用户</a-button
                  >
                  <a-popover>
                    <template #content>
                      <a-space>
                        <a-button @click="download" type="dashed"
                          >下载模板表</a-button
                        >
                        <a-upload
                          :customRequest="upLoadFile"
                          :showUploadList="false"
                        >
                          <a-button type="primary" @click="importData"
                            >导入</a-button
                          >
                        </a-upload>
                      </a-space>
                    </template>
                    <a-button size="large"> 批量导入企业用户 </a-button>
                  </a-popover>
                </a-space>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <div
                class="user-list flex flex-between"
                v-for="(item, index) in enterprise_user"
                :key="index"
              >
                <span
                  >{{ item.enterprise_user_name }}&nbsp;&nbsp;
                  {{ item.enterprise_user_phone }}&nbsp;&nbsp;{{
                    item.enterprise_user_job
                  }}</span
                >
                <div>
                  <span
                    style="margin-right: 15px; cursor: pointer"
                    :class="{
                      'text-gray': true,
                      'text-blue': !item.enterprise_user_ismanager,
                    }"
                    @click="updateEnterpriseUserIsmanagerDataFun(item)"
                    >{{
                      item.enterprise_user_ismanager == 1
                        ? "取消管理员"
                        : "设为管理员"
                    }}</span
                  >
                  <span
                    class="text-red"
                    style="cursor: pointer"
                    @click="deleteEnterpriseUserDataFun(item)"
                    >删除</span
                  >
                </div>
              </div>
            </a-col>
            <a-col
              :span="24"
              v-if="
                enterprise?.enterprise_enter_status == 4 &&
                (enterprise.enterprise_leave_status == 0 ||
                  enterprise.enterprise_leave_status == -1) &&
                userData?.enterprise_user?.enterprise_user_ismanager == 1
              "
            >
              <a-form-item class="">
                <a-button
                  block
                  size="large"
                  html-type="submit"
                  class="btn"
                  type="primary"
                  :loading="loadingBtn"
                  @click="handleSubmit"
                >
                  申请退园
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
      <a-modal v-model:visible="visible" title="新增企业用户" :footer="null">
        <div class="user-box">
          <!-- <a-input
            v-model:value="user_phone"
            size="large"
            placeholder="请输入用户手机号码"
          /> -->
          <a-form
            :model="form2"
            ref="formRef2"
            layout="vertical"
            :colon="colon"
            @finish="handleOk"
          >
            <a-row type="flex" justify="space-between" :gutter="20">
              <a-col :span="24">
                <a-form-item
                  name="enterprise_user_name"
                  label="员工姓名"
                  :rules="{
                    required: true,
                    message: '请输入',
                  }"
                >
                  <a-input
                    v-model:value="form2.enterprise_user_name"
                    placeholder="请输入"
                    size="large"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item
                  name="enterprise_user_phone"
                  label="员工电话"
                  :rules="{
                    required: true,
                    message: '请输入',
                  }"
                >
                  <a-input
                    v-model:value="form2.enterprise_user_phone"
                    placeholder="请输入"
                    size="large"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="员工职位">
                  <a-input
                    v-model:value="form2.enterprise_user_job"
                    placeholder="请输入"
                    size="large"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item
                  name="enterprise_user_ismanager"
                  label="是否管理员"
                >
                  <a-switch v-model:checked="form2.enterprise_user_ismanager" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item class="">
                  <a-button
                    block
                    size="large"
                    type="primary"
                    html-type="submit"
                    class="btn"
                    style="margin: 20px auto"
                    :loading="loadingBtn"
                    @click="handleOk"
                  >
                    提交
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-modal>
      <leave-popup
        v-model:visible="leaveVisible"
        :enterpriseId="enterpriseId"
        @success="sendSuccess"
      ></leave-popup>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  createVNode,
} from "@vue/runtime-core";
import {
  getParkList,
  updateEnterpriseLeaveStatusData,
  getEnterpriseData,
  getEnterpriseUserList,
  insertEnterpriseUserData,
  deleteEnterpriseUserData,
  updateEnterpriseUserIsmanagerData,
  importSheet,importEnterpriseUserSheet
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message, Modal } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import uploadImage from "@/components/upload.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import LeavePopup from "../ucenter/components/leavePopup.vue";

export default defineComponent({
  name: "EnterpriseDetail",
  components: {
    uploadImage,
    LeavePopup,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    enterpriseId: {
      type: String,
      default: null,
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const formRef = ref();
    const formRef2 = ref();
    const route = useRoute();
    const pageData = reactive({
      colon: false,
      autosize: false,
      form: {
        park_id: null,
        enterprise_name: null,
        enterprise_logo: null,
        enterprise_usci: null,
        enterprise_legal_person: null,
        enterprise_people_num: null,
        enterprise_introduction: null,
        enterprise_contact_name: null,
        enterprise_contact_phone: null,
        enterprise_address: null,
        apply_sheet: null,
        license: null,
        idcardimg: null,
        permit: null,
        financial: null,
        honor: null,
        real: null,
        enterprise_user_phone: null,
        enterprise_user_name: null,
        enterprise_user_job: null,
        // enterprise_license: null,
        // enterprise_material: null
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      park: null,
      enterprise: null,
      enterpriseId: null,
      enterprise_user: null,
      visible: false,
      user_phone: null,
      leaveVisible: false,
      form2: {
        enterprise_user_phone: null,
        enterprise_user_name: null,
        enterprise_user_job: null,
        enterprise_user_ismanager: false,
      },
    });
    const getParkListFun = () => {
      getParkList()
        .then((res) => {
          if (res.code == 200) {
            pageData.park = res.data.park;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getParkListFun();

    const updateEnterpriseLeaveStatusDataFun = () => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "确定退园？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          updateEnterpriseLeaveStatusData({
            user_id: pageData.user_id,
            enterprise_id: pageData.enterpriseId,
          })
            .then((res) => {
              if (res.code == 200) {
                message.success("操作成功");
              } else {
                message.error(res.msg);
              }
            })
            .catch((res) => {
              console.log(res);
            })
            .finally(() => {
              pageData.loadingBtn = false;
              pageData.loadingForm = false;
            });
        },
      });
    };
    const sendSuccess = () => {
      window.history.go(-1);
      message.success("操作成功");
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      // updateEnterpriseLeaveStatusDataFun();
      pageData.leaveVisible = true;
    };
    const getImageStatus = (e) => {
      formRef.value.clearValidate(e);
    };
    const getEnterpriseDataFun = (_enterpriseId) => {
      getEnterpriseData({
        enterprise_id: _enterpriseId,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.enterprise = res.data.enterprise;
            pageData.form = { ...res.data.enterprise };
            if (res.data.enterprise.enterprise_enter_material) {
              let enterprise_enter_material = JSON.parse(
                res.data.enterprise.enterprise_enter_material
              );
              pageData.form.apply_sheet = enterprise_enter_material.apply_sheet;
              pageData.form.license = enterprise_enter_material.license;
              pageData.form.idcardimg = enterprise_enter_material.idcardimg;
              pageData.form.permit = enterprise_enter_material.permit;
              pageData.form.financial = enterprise_enter_material.financial;
              pageData.form.honor = enterprise_enter_material.honor;
              pageData.form.real = enterprise_enter_material.real;
            }
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    const getEnterpriseUserListFun = (_enterpriseId) => {
      getEnterpriseUserList({
        enterprise_id: _enterpriseId,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.enterprise_user = res.data.enterprise_user;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    const handleOk = (e) => {
      e.preventDefault();
      formRef2.value
        .validate()
        .then((res) => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          insertEnterpriseUserData({
            user_id: pageData.user_id,
            enterprise_id: pageData.enterpriseId,
            ...res,
            enterprise_user_ismanager: res.enterprise_user_ismanager ? 1 : 0,
          })
            .then((res) => {
              if (res.code == 200) {
                message.success("操作成功");
                getEnterpriseDataFun(pageData.enterpriseId);
                getEnterpriseUserListFun(pageData.enterpriseId);
                pageData.visible = false;
                pageData.user_phone = "";
              } else {
                message.error(res.msg);
              }
            })
            .catch((res) => {
              console.log(res);
            })
            .finally(() => {
              pageData.loadingBtn = false;
              pageData.loadingForm = false;
            });
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const deleteEnterpriseUserDataFun = (_item) => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "确定删除该企业用户？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          deleteEnterpriseUserData({
            user_id: pageData.user_id,
            enterprise_id: pageData.enterpriseId,
            enterprise_user_id: _item.enterprise_user_id,
          })
            .then((res) => {
              if (res.code == 200) {
                message.success("操作成功");
                getEnterpriseDataFun(pageData.enterpriseId);
                getEnterpriseUserListFun(pageData.enterpriseId);
              } else {
                message.error(res.msg);
              }
            })
            .catch((res) => {
              console.log(res);
            })
            .finally(() => {
              pageData.loadingBtn = false;
              pageData.loadingForm = false;
            });
        },
      });
    };
    const updateEnterpriseUserIsmanagerDataFun = (item) => {
      pageData.loadingBtn = true;
      pageData.loadingForm = true;
      updateEnterpriseUserIsmanagerData({
        user_id: pageData.user_id,
        enterprise_id: pageData.enterpriseId,
        enterprise_user_id: item.enterprise_user_id,
        enterprise_user_ismanager: item.enterprise_user_ismanager == 1 ? 0 : 1,
      })
        .then((res) => {
          if (res.code == 200) {
            message.success("操作成功");
            getEnterpriseDataFun(pageData.enterpriseId);
            getEnterpriseUserListFun(pageData.enterpriseId);
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const handleUserSubmit = () => {};
    watch(
      () => route.query,
      (val) => {
        if (route.path == "/enterprise/detail") {
          if (val?.t) {
            let query = decode(val?.t).split(",");
            if (query[0]) {
              pageData.loading = true;
              pageData.enterpriseId = query[0];
              getEnterpriseDataFun(query[0]);
              getEnterpriseUserListFun(query[0]);
            } else {
              pageData.loading = false;
              pageData.enterprise = null;
              message.error("暂无企业入驻信息");
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const download = () => {
      let Aa = document.createElement("a");
      Aa.download = "企业员工导入模板表.xlsx";
      Aa.style.display = "none";
      Aa.href = "/static/sheet/企业员工导入模板表.xlsx";
      document.body.appendChild(Aa);
      Aa.click();
      Aa.remove();
    };
    const importData = () => {};

    const upLoadFile = (res) => {
      let a={ user_id: pageData.user_id}
      const formData = new FormData();
      formData.append('file', res.file);
      formData.append('user_id',a.user_id);
      importSheet(formData)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            importEnterpriseUserSheet({
              src: res.data.src,
              user_id: pageData.user_id,
              enterprise_id: pageData.enterpriseId,
            }).then((ress) => {
              if (ress.code == 200) {
                message.success("导入成功");
                getEnterpriseUserListFun(pageData.enterpriseId);
              } else {
                message.error(ress.msg);
              }
            });
          } else {
            message.error(res.msg);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    };

    return {
      ...toRefs(pageData),
      formRef,
      formRef2,
      handleSubmit,
      getImageStatus,
      handleOk,
      deleteEnterpriseUserDataFun,
      sendSuccess,
      updateEnterpriseUserIsmanagerDataFun,
      handleUserSubmit,
      download,
      upLoadFile,
      importData,
    };
  },
});
</script>
<style lang="less">
.enterprise-info-page {
  .info-wrap {
    width: 1000px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
    .input-hide {
      visibility: hidden;
      position: absolute;
      top: -999px;
      left: -999px;
    }
    .user-list {
      font-size: 18px;
      padding: 20px 0;
      border-bottom: 1px #dfdfdf solid;
    }
  }
}
</style>
